import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestParseState,
  RequestParseFailureAction,
  mutateParseErrorState,
  requestState,
} from '../toolkitUtils';

const initialState = {
  requests: {
    read: requestParseState(),
    readHistory: requestState(),
    readMesh: requestState(),
  },
};

export type TrendlineState = typeof initialState;

const devicesGraphSlice = createSlice({
  name: 'devicesGraph',
  initialState,
  reducers: {
    // graph
    readDevicesGraphRequest(state) {
      mutateRequestState(state.requests.read);
    },
    readDevicesGraphSuccess(state) {
      mutateSuccessState(state.requests.read);
    },
    readDevicesGraphFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.read, action.payload.error);
    },
    readDevicesGraphParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.read, action.payload.parseError);
    },
    // history dates
    readHistoryRequest(state) {
      mutateRequestState(state.requests.readHistory);
    },
    readHistorySuccess(state) {
      mutateSuccessState(state.requests.readHistory);
    },
    readHistoryFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readHistory, action.payload.error);
    },
    // mesh net
    readMeshRequest(state) {
      mutateRequestState(state.requests.readMesh);
    },
    readMeshSuccess(state) {
      mutateSuccessState(state.requests.readMesh);
    },
    readMeshFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readMesh, action.payload.error);
    },
  },
});

export const devicesGraphActions = devicesGraphSlice.actions;

export default devicesGraphSlice.reducer;
