import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import ConnectedDevicesIcon from '@mitch528/mdi-material-ui/Devices';
// import TimeMachineIcon from '@mitch528/mdi-material-ui/TimelineClock';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { CustomerArea } from '../../../../entities/Customer';
import useAuthentication from '../../../../hooks/useAuthentication';
import { useCustomer } from '../../../../hooks/useCustomer';
import { useProblems } from '../../../../hooks/useProblems';
import { MAP_T_HISTORY_GRANTS } from '../../../../utils/auth';
import { getMaterialColors } from '../../../../utils/materialColors';
import { useApplicationError } from '../../../ApplicationError';
import { CompactAccordion } from '../../../CompactAccordion';
import KeyValueList from '../../../KeyValueList';
import LoaderView from '../../../LoaderView';
import { Scroller } from '../../../Scroller';
import HubDetailsDialog, { HubDetailsButtonProps } from '../../Dashboard/HubDetailsDialog/HubDetailsDialog';
import WizardActionSection from '../Wizard/components/WizardActionSection';
import AnalyzedProblemsAccordion from './AnalyzedProblemsAccordion';
import FoundProblemsAccordion from './FoundProblemsAccordion';

export default function LeftColumn(): JSX.Element {
  const { customer: customerId } = useParams() as { customer?: string | undefined };
  const { customerInfo, customerLastTestInfo, readCustomerInfoRequest, readCustomerRequest } = useCustomer();
  const {
    customerLastTestInfo: lastTestInfo,
    customerActionList: actions,
    readCustomerActionListRequest: actionsRequest,
  } = useCustomer();
  const { foundProblems, analyzedProblems, readProblems, readProblemsRequest } = useProblems();
  const { t } = useTranslation();
  const { hasGrants } = useAuthentication();

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area');

  const applicationErrors = useMemo(
    () => [
      readCustomerRequest.error,
      actionsRequest.error,
      actionsRequest.parseError,
      readCustomerInfoRequest.error,
      readCustomerInfoRequest.parseError,
      readProblemsRequest.error,
      readProblemsRequest.parseError,
    ],
    [readCustomerRequest, actionsRequest, readCustomerInfoRequest, readProblemsRequest]
  );
  useApplicationError('CustomerInfo', applicationErrors);

  useEffect(() => {
    if (customerId) {
      readProblems(customerId);
    }
  }, [readProblems, customerId]);

  // Latest Customer Tests Informations
  const latestInfos = useMemo(() => {
    if (!lastTestInfo || !lastTestInfo.info) {
      return [];
    }

    const colors = getMaterialColors(lastTestInfo.info.length);
    return lastTestInfo.info.map((info, index) => {
      return (
        <CompactAccordion
          key={info.title}
          expanded={info.expanded}
          sx={{ m: 1, backgroundColor: colors[index] + '10' }}
          summary={
            <Typography fontWeight="bold" variant="body1">
              {info.title}
            </Typography>
          }
        >
          <AccordionDetails>
            {info.sub_info.map((sub_info) => {
              return (
                <Box
                  key={sub_info.sub_title}
                  sx={{
                    margin: 2,
                    marginTop: 0,
                    paddingTop: 2,
                    borderTop: '1px solid #eeeeee',
                    '&:first-of-type': {
                      borderTopWidth: 0,
                      paddingTop: 0,
                    },
                  }}
                >
                  {sub_info.sub_title && (
                    <Typography variant="h6" gutterBottom>
                      {sub_info.sub_title}
                    </Typography>
                  )}
                  <KeyValueList
                    entries={sub_info.value.map((v) => {
                      return {
                        key: v.variable,
                        value: v.value as string,
                        warning: Boolean(v.alert_modification_data),
                      };
                    })}
                  />
                </Box>
              );
            })}
          </AccordionDetails>
        </CompactAccordion>
      );
    });
  }, [lastTestInfo]);

  // Trendlines Sections Definition
  const trendlineSections = useMemo(() => {
    if (!actions?.trendlines) {
      return null;
    }
    const trendlineGroups = [] as string[];
    actions.trendlines.forEach((actionItem) => {
      if (!actionItem.standalone && actionItem.chart_group && !trendlineGroups.includes(actionItem.chart_group))
        trendlineGroups.push(actionItem.chart_group);
    });
    const trendlineSectionsColors = getMaterialColors(trendlineGroups.length);

    return (
      <>
        {/* All grouped trendlines first */}
        {trendlineGroups.map((groupName: string, i: number) => {
          const filteredGroups = actions.trendlines.filter((trendlineItem) => {
            if (!trendlineItem.standalone && trendlineItem.chart_group && trendlineItem.chart_group === groupName)
              return true;
            else return false;
          });
          return (
            <CompactAccordion
              key={`${groupName}-${i}`}
              sx={{ margin: 1, backgroundColor: trendlineSectionsColors[i] + '10' }}
              summary={
                <Typography fontSize={13} fontWeight="400" variant="body1">
                  {groupName}
                </Typography>
              }
            >
              {filteredGroups.map((trendline, i) => (
                <HubDetailsDialog
                  key={`${trendline.chart_name}-${i}`}
                  type={trendline.chart_type}
                  customerId={customerId || ''}
                  title={trendline.chart_name}
                  description={trendline.chart_description}
                  buttonComponent={(props: HubDetailsButtonProps) => (
                    <ListItem button sx={{ maxHeight: 48 }} onClick={props.onClick}>
                      {trendline.chart_name}
                    </ListItem>
                  )}
                />
              ))}
            </CompactAccordion>
          );
        })}
        {/* Then standlone trendlines */}
        {actions.trendlines.map((trendline, i) => {
          if (customerId && trendline.standalone) {
            return (
              <HubDetailsDialog
                key={`${trendline.chart_name}-${i}`}
                type={trendline.chart_type}
                customerId={customerId || ''}
                title={trendline.chart_name}
                description={trendline.chart_description}
                buttonComponent={(props: HubDetailsButtonProps) => (
                  <ListItem button sx={{ maxHeight: 48 }} onClick={props.onClick}>
                    {trendline.chart_name}
                  </ListItem>
                )}
              />
            );
          }
          return null;
        })}
      </>
    );
  }, [actions.trendlines, customerId]);

  // Actions Sections Definition
  const actionSections = useMemo(() => {
    if (!actions?.actions) {
      return null;
    }
    const actionGroups = [] as string[];
    actions.actions.forEach((actionItem) => {
      if (!actionItem.standalone && actionItem.section && !actionGroups.includes(actionItem.section))
        actionGroups.push(actionItem.section);
    });
    const actionSectionsColors = getMaterialColors(actionGroups.length);

    return (
      <>
        {/* All grouped actions first */}
        {actionGroups.map((groupName: string, i: number) => {
          const filteredActions = actions.actions.filter((actionItem) => {
            if (!actionItem.standalone && actionItem.section && actionItem.section === groupName) return true;
            else return false;
          });
          return (
            <CompactAccordion
              key={groupName + i}
              sx={{ margin: 1, backgroundColor: actionSectionsColors[i] + '10' }}
              summary={
                <Typography fontSize={13} fontWeight="400" variant="body1">
                  {groupName}
                </Typography>
              }
            >
              {filteredActions.map((action, i) => {
                if (customerId) {
                  if (action.action_id !== 'execute-test' && action.action_id !== 'request-history') {
                    return (
                      <WizardActionSection
                        key={`${action.action_id}-${i}`}
                        sidebar
                        customerId={customerId}
                        wizardId={undefined}
                        action={action}
                        wizard_action_results={[]}
                      />
                    );
                  }
                }
                return null;
              })}
            </CompactAccordion>
          );
        })}
        {/* Then standlone actions */}
        {actions.actions.map((action, i) => {
          if (customerId && action.standalone) {
            if (action.action_id !== 'execute-test' && action.action_id !== 'request-history') {
              return (
                <WizardActionSection
                  key={`${action.action_id}-${i}`}
                  sidebar
                  customerId={customerId}
                  wizardId={undefined}
                  action={action}
                  wizard_action_results={[]}
                />
              );
            }
          }
          return null;
        })}
      </>
    );
  }, [actions, customerId]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LoaderView condition={Boolean(customerId) && Boolean(actions) && Boolean(customerInfo)} minHeight="66%">
        <Scroller>
          <FoundProblemsAccordion foundProblems={foundProblems} />
          <AnalyzedProblemsAccordion analyzedProblems={analyzedProblems} />
          <CompactAccordion
            sx={{
              maxHeight: 'calc(100% - 194px)',
              overflowY: 'auto',
              padding: 0,
              '& .MuiListItem-root': {
                typography: 'button',
                color: 'primary.main',
                lineHeight: 5,
              },
            }}
            summary={
              <Typography fontWeight="bold" variant="body1">
                {t('dashboard:customerInfo:customerInfo')}
              </Typography>
            }
          >
            {customerLastTestInfo.info && latestInfos}
          </CompactAccordion>

          <CompactAccordion
            summary={
              <Typography fontWeight="bold" variant="body1">
                {t('dashboard:customerInfo:advancedAnalysis')}
              </Typography>
            }
          >
            <List
              sx={{
                padding: 0,
                '& .MuiListItem-root': {
                  typography: 'button',
                  color: 'primary.main',
                  lineHeight: 5,
                },
              }}
            >
              {area !== CustomerArea.TV_RDKV && area !== CustomerArea.Q_LEGACY && hasGrants(MAP_T_HISTORY_GRANTS) && (
                <HubDetailsDialog
                  customerId={customerId ?? ''}
                  type="mapT"
                  title={t('dashboard:hubDetailsDialogMapT:title')}
                  buttonComponent={(props: HubDetailsButtonProps) => (
                    <ListItem button sx={{ maxHeight: 48 }} onClick={props.onClick}>
                      {t('dashboard:hubDetailsDialogMapT:title')}
                    </ListItem>
                  )}
                />
              )}
              {area !== CustomerArea.TV_RDKV && area !== CustomerArea.Q_LEGACY && (
                <HubDetailsDialog
                  title={t('dashboard:customerInfo:devices')}
                  type="devicesGraph"
                  customerId={customerId ?? ''}
                  buttonComponent={(props: HubDetailsButtonProps) => (
                    <ListItem button sx={{ maxHeight: 48 }} onClick={props.onClick}>
                      {/* TODO: show in future releases */}
                      {/* <ListItemIcon sx={{ minWidth: 34 }}>
                      <ConnectedDevicesIcon color="primary" />
                    </ListItemIcon> */}
                      {t('dashboard:customerInfo:devices')}
                    </ListItem>
                  )}
                />
              )}
              {area === CustomerArea.Q_LEGACY && (
                <HubDetailsDialog
                  title={t('dashboard:customerInfo:dialogs:mesh:title')}
                  type="mesh"
                  customerId={customerId ?? ''}
                  buttonComponent={(props: HubDetailsButtonProps) => (
                    <ListItem sx={{ maxHeight: 48, cursor: 'pointer' }} onClick={props.onClick}>
                      {t('dashboard:customerInfo:dialogs:mesh:title')}
                    </ListItem>
                  )}
                />
              )}
              {/* <HubDetailsDialog
                title="Storico Dispositivi Connessi"
                type="time-machine"
                customerId={customerId || ''}
                buttonComponent={(props: HubDetailsButtonProps) => (
                  <ListItem button sx={{ maxHeight: 48 }} onClick={props.onClick}>
                    <ListItemIcon sx={{ minWidth: 34 }}>
                      <TimeMachineIcon color="primary" />
                    </ListItemIcon>
                    Storico Dispositivi Connessi
                  </ListItem>
                )}
              /> */}
              {trendlineSections}
            </List>
          </CompactAccordion>

          <CompactAccordion
            summary={
              <Typography fontWeight="bold" variant="body1">
                {t('dashboard:customerInfo:actions')}
              </Typography>
            }
          >
            <AccordionDetails sx={{ padding: 0 }}>
              <Scroller>
                <List
                  sx={{
                    padding: 0,
                    '& .MuiListItem-root': {
                      typography: 'button',
                      color: 'primary.main',
                      lineHeight: 5,
                    },
                  }}
                >
                  {actionSections}
                </List>
              </Scroller>
            </AccordionDetails>
          </CompactAccordion>
        </Scroller>
      </LoaderView>
    </Box>
  );
}
