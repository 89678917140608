import { InterceptorRepository } from './InterceptorRepository';

class DevicesGraphRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '',
    });
  }

  async get(customerId: string): Promise<unknown> {
    const { data } = await this.axios.get(`/customers/${customerId}/info/devices`);
    return data;
  }

  async getByDate(customerId: string, timestamp: number): Promise<unknown> {
    const { data } = await this.axios.get(`/sources/map/${customerId}/${timestamp}`);
    return data;
  }

  async getHistory(customerId: string, dateFrom?: number, dateTo?: number): Promise<{ dates: number[] } | void> {
    const { data } = await this.axios.get(`/sources/map/${customerId}`, {
      params: { start_date: dateFrom, end_date: dateTo },
    });
    return data;
  }

  async getMesh(serialNumber: string): Promise<string | null> {
    const { data } = await this.axios.get(`/customers/${serialNumber}/info/mesh/`);
    return data;
  }
}

export default new DevicesGraphRepository();
