import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FitToPageOutline from 'mdi-material-ui/FitToPageOutline';

import { useDevicesGraph } from '../../../../hooks/useDevicesGraph';
import LoaderView from '../../../LoaderView';

export interface HubDetailsButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

interface HubDetailsDialogMeshProps {
  serialNumber?: string;
  title?: string;
  size: { width: number; height: number };
  visible: boolean;
  buttonComponent?: (props: HubDetailsButtonProps) => JSX.Element;
}

export default function HubDetailsDialogMesh(props: HubDetailsDialogMeshProps): JSX.Element {
  const { readMesh, readMeshRequest } = useDevicesGraph();
  const { t } = useTranslation();

  const [url, setUrl] = useState<string | null | undefined>();
  const [fit, setFit] = useState<boolean>(true);

  useEffect(() => {
    // on dialog open
    if (props.serialNumber && props.visible) {
      readMesh(props.serialNumber).then(setUrl);
    }
  }, [props.visible, readMesh, props.serialNumber]);

  return (
    <Box
      width={props.size.width}
      height={props.size.height}
      style={{ overflow: 'auto' }}
      display={fit ? 'block' : 'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <LoaderView condition={!readMeshRequest.inProgress}>
        {url && props.visible ? (
          <>
            <Button
              color={'primary'}
              size={'small'}
              variant={fit ? 'contained' : 'outlined'}
              onClick={() => setFit(!fit)}
              sx={{
                padding: '12px',
                margin: 0,
                minWidth: 0,
                borderRadius: '50%',
                position: 'absolute',
                right: '15px',
                top: '60px',
              }}
            >
              <FitToPageOutline />
            </Button>
            <img
              src={url}
              alt={t('dashboard:customerInfo:dialogs:mesh:title')}
              style={
                fit
                  ? {
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }
                  : { margin: 'auto' }
              }
            />
          </>
        ) : (
          <Box sx={{ p: 4 }}>
            <p>{t('dashboard:customerInfo:dialogs:mesh:unavailable')}</p>
          </Box>
        )}
      </LoaderView>
    </Box>
  );
}
